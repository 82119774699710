<template>
  <section class="w-full">
    <header-register :showButtonView="false" @register="handleActivePageOperation">Ensaio</header-register>
    <main class="w-full">
      <ManageEnsaioEdit/>
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageEnsaioEdit from "@/components/groupProducts/ensaio/components/edit/ManageEnsaioEdit.vue";


export default {
  name: "EnsaiosEdit",
  components: {
    HeaderRegister,
    ManageEnsaioEdit
},
};
</script>
